import React from "react"
import { Player } from "@lottiefiles/react-lottie-player"
import { Link } from "gatsby"
import Seo from "../components/Seo"

const Page404 = () => {
  return (
    <>
      <Seo title="404" />
      <section class="section404">
        <div className="section404__animation">
          <Player
            autoplay={true}
            controls={false}
            loop={true}
            src="https://assets2.lottiefiles.com/packages/lf20_urmTP4.json"
            style={{ width: "100%" }}
            background="transparent"
            speed="1"
          ></Player>
        </div>
        <div className="section404__text">Whooops, Page Not Found</div>
        <div className="section404__cta">
          <Link to="/">Go To Home</Link>
        </div>
      </section>
    </>
  )
}

export default Page404
